import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  ContactForm,
  createDefaultMessageFromState,
  FoodMenu,
} from "@bluefin/components";
import { Header, Modal, Button, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class MenuPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessMenuModifierSet,
      allFishermanBusinessMenuModifier,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"menu-hero"}>
              <Background.Color width={16} color={"white"}>
                <Header as={"h1"}>Our Menu</Header>
                <Modal
                  header={"Submit Your Catering Request"}
                  size={"medium"}
                  trigger={
                    <Button
                      secondary={true}
                      content={"Submit Catering Request"}
                      className={"catering-request-cta"}
                    />
                  }
                >
                  <Modal.Content>
                    <ContactForm
                      constructMessage={createDefaultMessageFromState}
                      fields={[
                        {
                          label: "name",
                          placeholder: "Full Name",
                          type: "input",
                          required: true,
                        },
                        {
                          label: "email",
                          placeholder: "Email",
                          type: "input",
                          inputType: "email",
                          required: true,
                        },
                        {
                          label: "phone",
                          placeholder: "Phone Number",
                          type: "input",
                          required: true,
                        },
                        {
                          label: "message",
                          placeholder: "Additional Message",
                          type: "textarea",
                          required: false,
                        },
                      ]}
                      buttonText={"Send"}
                      centeredHeaders={true}
                      className={""}
                      fluidButton={true}
                      header={""}
                      inline={false}
                      showLabels={true}
                      subheader={""}
                      termsCopy={""}
                      withContainer={true}
                      locationId={fishermanBusiness.primaryLocation._id}
                      businessId={fishermanBusiness._id}
                      businessType={fishermanBusiness.type}
                    />
                  </Modal.Content>
                </Modal>
              </Background.Color>
              <Background.Image
                width={16}
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
        >
          <Grid
            stackable={true}
            className={"menu-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"16"} textAlign={"left"}>
              <FoodMenu
                header={""}
                centerNavigation={true}
                categoryDisplayVariant={"HorizontalCardRow"}
                itemDisplayVariant={"HorizontalCard"}
                menu={{
                  schedules: allFishermanBusinessMenuSchedule.nodes,
                  categories: allFishermanBusinessMenuCategory.nodes,
                  items: allFishermanBusinessMenuItem.nodes,
                  modifierSets: allFishermanBusinessMenuModifierSet.nodes,
                  modifiers: allFishermanBusinessMenuModifier.nodes,
                }}
                business={{
                  id: fishermanBusiness._id,
                  type: fishermanBusiness.type,
                }}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Menu" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCategory {
      nodes {
        items
        description
        name
        _id
      }
    }
    allFishermanBusinessMenuItem {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
      }
    }
    allFishermanBusinessMenuModifierSet {
      nodes {
        _id
        minAllowed
        modifiers
        name
      }
    }
    allFishermanBusinessMenuModifier {
      nodes {
        _id
        name
        price
      }
    }
  }
`;
